import { VueRenderer } from '@tiptap/vue-2';
import tippy from 'tippy.js';
import store from '@/core/common/services/vuex-store.js';

import MentionList from './mentionlist.vue';

export default {
  items: ({ query }) => {
    return store.getters.getAllUsers
      .filter(({ givenName }) => givenName !== null && !givenName.toLowerCase().includes('test'))
      .filter(({ surname }) => surname !== null && !surname.toLowerCase().includes('test'))
      .filter(user => user.jobTitle !== null)
      .filter(({ mail }) => mail !== null)
      .map(({ givenName, surname, id }) => ({ name: givenName + ' ' + surname, id }))
      .filter(item => item.name.toLowerCase().startsWith(query.toLowerCase()))
      .slice(0, 5);
  },

  render: () => {
    let component;
    let popup;

    return {
      onStart: props => {
        component = new VueRenderer(MentionList, {
          parent: this,
          propsData: props,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};
